.contact {
  margin-top: 150px;
}

.contact__map {
  height: 100%;
  width: 100%;
}

@media (max-width: 991px) {
  .contact {
    margin-top: 46px;
  }

  .contact__map {
    height: 500px;
    margin-top: 30px;
  }
}

small {
  color: #dc3545;
}

small.success {
  color: green;
}

.error {
  border: 1px solid #dc3545 !important;
}

.btn-brown {
  width: 100px;
  height: 45px;
  box-shadow: none;
  color: #fff !important;
  background-color: #795548 !important;
}

.form-check-input.form-ag-input[type="checkbox"] {
  box-shadow: none;
  border: 1px solid gray;
  border-radius: 2px;
  outline: 0;
}

.form-check-input.form-ag-input:focus {
  box-shadow: none;
  border: 1px solid gray;
  outline: 0;
}

.form-check-input.form-ag-input:checked {
  background-color: #795548;
}

.form-check-input.form-ag-input:checked:focus {
  box-shadow: none;
  border: none;
  outline: 0;
}

.grecaptcha-badge {
  bottom: 92px !important;
  right: 48px !important;
}

@media (min-width: 993px) and (max-width: 1199px) {
  .grecaptcha-badge {
    bottom: 27px !important;
    left: 50px !important;
  }
}

@media (max-width: 992px) {
  .grecaptcha-badge {
    bottom: -23px !important;
    left: 50px !important;
  }
}

/*# sourceMappingURL=kontakt.a5ec6135.css.map */
